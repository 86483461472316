
import { onMounted, onUnmounted, computed } from "vue";
import { useAbility } from "@casl/vue";
import { useRouter } from "vue-router";
import ActivityTable from "@/components/users/userProfile/fields/UserActivityDataTable.vue";
import LoadingSpinner from "@/components/general/LoadingSpinner.vue";
import Pagination from "@/components/pagination/Pagination.vue";
import store from "@/store/index";

type Pagination = {
  currentPage: number;
  lastPage: number;
};

export default {
  name: "user-activity-tab",
  components: {
    ActivityTable,
    LoadingSpinner,
    Pagination
  },
  setup() {
    const user = computed(() => store.getters["UsersModule/getSingleUser"]);
    const loading = computed(
      (): boolean => store.getters["ActivitiesModule/loading"]
    );
    const data = computed(
      (): object[] => store.getters["ActivitiesModule/getData"]
    );
    const pagination = computed(
      (): Pagination => store.getters["ActivitiesModule/getPagination"]
    );

    onMounted(() => {
        store.dispatch("ActivitiesModule/fetchData", user.value.id);
    });

    onUnmounted(() => {
      store.dispatch("ActivitiesModule/resetState");
    });

    function handlePageChange(value: number) {
      store.dispatch("ActivitiesModule/changePage", value);
    }

    return {
      data,
      loading,
      pagination,
      handlePageChange
    };
  }
};
