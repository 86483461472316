import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "fs-7 fw-bold" }
const _hoisted_2 = { class: "text-end" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("tr", _hoisted_1, [
    _createElementVNode("td", null, _toDisplayString($props.entry.type), 1),
    _createElementVNode("td", null, _toDisplayString($setup.formatedTime), 1),
    _createElementVNode("td", null, _toDisplayString($props.entry.description), 1),
    _createElementVNode("td", _hoisted_2, _toDisplayString($props.entry.properties.IP), 1)
  ]))
}