
import { computed, PropType } from "vue";
import moment from "moment";

type Activity = {
  id: number;
  type: string;
  description: string;
  created_at: string;
  properties: object;
};

export default {
  name: "user-activity-table-row",
  props: {
    entry: {
      type: Object as PropType<Activity>,
      required: true
    }
  },
  setup(props) {
    const formatedTime = computed(() => {
      return moment(props.entry.created_at).format("MMMM Do YYYY, h:mm:ss a");
    });

    return {
      formatedTime
    };
  }
};
