
import { PropType } from "vue";
import ActivityTableRow from "@/components/users/userProfile/fields/UserActivityTableRow.vue";

export default {
  name: "user-activity-data-table",
  components: {
    ActivityTableRow
  },
  props: {
    tableData: {
      type: Array as PropType<object[]>,
      required: true
    }
  }
};
